import * as Yup from 'yup';

const nameRegex = /^[^\\/:"*?<>|#%]+$/;
const latinRegex = /^[A-Za-z\s]+$/;

const createValidationSchema = (
  t: (key: string, defaultValue: string) => string
) => {
  return Yup.object({
    email: Yup.string()
      .email(t('rotary-magazine-form.email.invalid', 'Invalid email address'))
      .required(
        t('rotary-magazine-form.email.required', 'Email address is required')
      ),

    'first-name': Yup.string()
      .matches(
        nameRegex,
        t(
          'rotary-magazine-form.firstName.invalidChars',
          'The following characters are not allowed: \\ / : * ? “ < > | # %'
        )
      )
      .matches(
        latinRegex,
        t(
          'rotary-magazine-form.firstName.nonLatin',
          'Field cannot contain non-latin characters'
        )
      )
      .required(
        t('rotary-magazine-form.firstName.required', 'First name is required')
      ),

    'last-name': Yup.string()
      .matches(
        nameRegex,
        t(
          'rotary-magazine-form.lastName.invalidChars',
          'The following characters are not allowed: \\ / : * ? “ < > | # %'
        )
      )
      .matches(
        latinRegex,
        t(
          'rotary-magazine-form.lastName.nonLatin',
          'Field cannot contain non-latin characters'
        )
      )
      .required(
        t('rotary-magazine-form.lastName.required', 'Last name is required')
      ),

    address: Yup.string().required(
      t(
        'rotary-magazine-form.address.required',
        'Address line 1 cannot be empty'
      )
    ),

    'country-dropdown': Yup.string().required(
      t('rotary-magazine-form.country.required', 'Country/Region is required')
    ),

    city: Yup.string()
      .min(
        2,
        t(
          'rotary-magazine-form.city.min',
          'Field requires minimum 2 characters'
        )
      )
      .required(t('rotary-magazine-form.city.required', 'City is required')),

    'state-dropdown': Yup.string().when('country-dropdown', {
      is: (country: string) => ['IND', 'USA', 'CAN'].includes(country),
      then: Yup.string().required(
        t(
          'rotary-magazine-form.state.required',
          'State/Province/Territory is required'
        )
      ),
      otherwise: Yup.string(),
    }),

    'postal-code': Yup.string().required(
      t('rotary-magazine-form.postalCode.required', 'Postal code is required')
    ),

    'club-name': Yup.string().required(
      t('rotary-magazine-form.clubName.required', 'Club name is required')
    ),
  });
};

export default createValidationSchema;
