import React, { useRef, useState } from 'react';

import Alert from '@rotaryintl/harris-alert';
import Link from '@rotaryintl/harris-link';
import List from '@rotaryintl/harris-list';
import { FormikErrors, FormikTouched } from 'formik';

import { OneColumn } from '@components/Layouts/OneColumn';

import { useTranslation } from '@external/react-i18next';

import RotaryMagazineForm from '@presenters/web/components/RotaryMagazine/RotaryMagazineForm';

import './styles.css';

type CustomMessages = {
  [key: string]: {
    [errorMessage: string]: string;
  };
};

type Errors = {
  [key: string]: string;
};

interface ErrorValues {
  email: string;
  'first-name': string;
  'last-name': string;
  address: string;
  city: string;
  'country-dropdown': string;
  'state-dropdown': string;
  'postal-code': string;
  'club-name': string;
}

interface TouchedValues {
  email: boolean;
  'first-name': boolean;
  'last-name': boolean;
  address: boolean;
  city: boolean;
  'country-dropdown': boolean;
  'state-dropdown': boolean;
  'postal-code': boolean;
  'club-name': boolean;
}

const RotaryMagazinePage: React.FC = () => {
  const { t } = useTranslation();
  const [errorSummary, setErrorSummary] = useState<Errors>({});

  const targetRef = useRef<HTMLDivElement | null>(null);

  const handleSetErrors = (
    errors: FormikErrors<ErrorValues>,
    touched: FormikTouched<TouchedValues>,
    submitted?: boolean
  ) => {
    let filteredErrors: FormikErrors<ErrorValues> = {};
    Object.keys(errors).forEach(key => {
      if (touched[key as keyof TouchedValues]) {
        filteredErrors = {
          ...filteredErrors,
          [key]: errors[key as keyof FormikErrors<ErrorValues>],
        };
      }
    });

    if (submitted && Object.keys(errors)?.length && targetRef.current) {
      const offset = 25;
      const topPosition =
        targetRef.current.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }

    setErrorSummary(getCustomErrorMessage(filteredErrors, t));
  };

  const getCustomErrorMessage = (
    errors: Errors,
    t: (key: string, defaultValue: string) => string
  ): Errors => {
    const customMessages: CustomMessages = {
      email: {
        [t(
          'rotary-magazine-form.email.required',
          'Email address is required'
        )]: t(
          'rotary-magazine-form.email.required-2',
          '<b>Email address</b> is required.'
        ),
        [t('rotary-magazine-form.email.invalid', 'Invalid email address')]: t(
          'rotary-magazine-form.email.invalid-2',
          '<b>Email address</b> is invalid.'
        ),
      },
      'first-name': {
        [t(
          'rotary-magazine-form.firstName.required',
          'First name is required'
        )]: t(
          'rotary-magazine-form.firstName.required-2',
          '<b>First name</b> is required.'
        ),
        [t(
          'rotary-magazine-form.firstName.invalidChars',
          'The following characters are not allowed: \\ / : * ? “ < > | # %'
        )]: t(
          'rotary-magazine-form.firstName.invalidChars-2',
          '<b>First name</b> cannot contain invalid characters: \\ / : * ? “ < > | # %'
        ),
        [t(
          'rotary-magazine-form.firstName.nonLatin',
          'Field cannot contain non-latin characters'
        )]: t(
          'rotary-magazine-form.firstName.nonLatin-2',
          '<b>First name</b> cannot contain non-latin characters.'
        ),
      },
      'last-name': {
        [t(
          'rotary-magazine-form.lastName.required',
          'Last name is required'
        )]: t(
          'rotary-magazine-form.lastName.required-2',
          '<b>Last name</b> is required.'
        ),
        [t(
          'rotary-magazine-form.lastName.invalidChars',
          'The following characters are not allowed: \\ / : * ? “ < > | # %'
        )]: t(
          'rotary-magazine-form.lastName.invalidChars-2',
          '<b>Last name</b> cannot contain invalid characters: \\ / : * ? “ < > | # %'
        ),
        [t(
          'rotary-magazine-form.lastName.nonLatin',
          'Field cannot contain non-latin characters'
        )]: t(
          'rotary-magazine-form.lastName.nonLatin-2',
          '<b>Last name</b> cannot contain non-latin characters.'
        ),
      },
      address: {
        [t(
          'rotary-magazine-form.address.required',
          'Address line 1 cannot be empty'
        )]: t(
          'rotary-magazine-form.address.required-2',
          '<b>Address</b> is required.'
        ),
      },
      'country-dropdown': {
        [t(
          'rotary-magazine-form.country.required',
          'Country/Region is required'
        )]: t(
          'rotary-magazine-form.country.required-2',
          '<b>Country/Region</b> is required.'
        ),
      },
      city: {
        [t('rotary-magazine-form.city.required', 'City is required')]: t(
          'rotary-magazine-form.city.required-2',
          '<b>City</b> is required.'
        ),
        [t(
          'rotary-magazine-form.city.min',
          'Field requires minimum 2 characters'
        )]: t(
          'rotary-magazine-form.city.min-2',
          '<b>City</b> requires a minimum of 2 characters.'
        ),
      },
      'state-dropdown': {
        [t(
          'rotary-magazine-form.state.required',
          'State/Province/Territory is required'
        )]: t(
          'rotary-magazine-form.state.required-2',
          '<b>State/Province/Territory</b> is required.'
        ),
      },
      'postal-code': {
        [t(
          'rotary-magazine-form.postalCode.required',
          'Postal code is required'
        )]: t(
          'rotary-magazine-form.postalCode.required-2',
          '<b>Postal code</b> is required.'
        ),
      },
      'club-name': {
        [t(
          'rotary-magazine-form.clubName.required',
          'Club name is required'
        )]: t(
          'rotary-magazine-form.clubName.required-2',
          '<b>Club name</b> is required.'
        ),
      },
    };

    return Object.keys(errors).reduce<Errors>((acc, key) => {
      const errorMessage = errors[key];

      if (customMessages[key]?.[errorMessage]) {
        return { ...acc, [key]: customMessages[key][errorMessage] };
      }

      return acc;
    }, {});
  };

  return (
    <OneColumn className="mb-20">
      <section className="rm-content-wrapper">
        <h1 className="rm-title text-gray-600 font-semibold mb-6 mt-8">
          {t('rotary-magazine.title', 'Rotary Magazine Digital Edition')}
        </h1>
        <section className="rm-content">
          <p className="text-gray-600 text-base normal-case leading-6 mb-12">
            {t(
              'rotary-magazine.description',
              `As a digital subscriber, you will receive a link to the new issue in your e-mail inbox each month from GTxcel. If you're not currently subscribed to `
            )}
            <span className="italic">Rotary</span>
            {t(
              'rotary-magazine.description.continue',
              ` magazine (English only) or if you have any additional questions, please contact`
            )}
            <Link to="data@rotary.org" className="rm-link">
              data@rotary.org
            </Link>
            .
          </p>
          <div ref={targetRef} />
          {Object.keys(errorSummary)?.length ? (
            <Alert
              isDismissible
              onClose={() => {}}
              variant="critical"
              className="mb-6"
            >
              <List listStyle="bulleted" className="rm-list">
                {Object.keys(errorSummary).map(key => (
                  <li key={key} className="rm-list-item">
                    {errorSummary[key]}
                  </li>
                ))}
              </List>
            </Alert>
          ) : (
            ''
          )}
          <span className="rm-reuired-fields text-gray-400 text-sm normal-case leading-5 inline-block mb-6">
            {t('rotary-magazine.required', '*indicates a required field')}
          </span>
          <h3 className="rm-sub-title">
            {t(
              'rotary-magazine.subscription-title',
              'Subscription information'
            )}
          </h3>
          <RotaryMagazineForm onErrorsChange={handleSetErrors} />
        </section>
      </section>
    </OneColumn>
  );
};

export default RotaryMagazinePage;
